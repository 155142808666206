@tailwind base;
@tailwind components;
@tailwind utilities;

.auth-form-err {
    @apply text-red-500 text-sm ml-1 -mt-2;
}

.add-tutor-announcement-form-err {
    @apply text-red-500 text-sm ml-1 mt-1;
}